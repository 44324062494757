<template>
  <div class="sidebar-container">
    <b-button v-b-toggle.sidebar-right pill variant="primary" class="sidebar-button">Show Map Options</b-button>
    <b-sidebar id="sidebar-right" :title=(data.sidebarTitle) class="sidebar" >
      <div v-if="(data.maptype = 'inr')" class="sidebar-content">
        <InrSidebar />
      </div>
      <div v-else class="sidebar-content">
        <p>This is the default sidebar. If you are seeing this is because something went wrong. Please contact the Administrator.</p>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
import InrSidebar from "@/components/MapSidebar/MapType/inrSidebar.vue";

export default {
  name: "MapSidebar",
  components: {
      InrSidebar
  }, 
  props: {
    data: {},
  }
};
</script>

<style scoped lang="scss">
.sidebar-container {
  //z-index: 10000;
  position: fixed;
  // height: 100%;
  height: 645px;
  overflow: hidden;
  width: 328px;
  margin-left: auto;
}
.sidebar-button{
    position: fixed;
    right: 2rem;
    top:0rem;
    max-width: 40rem;
    margin-top: 8rem;
}

.sidebar{
    position:fixed;
    right:0px;
    top:115px;
    // min-width: 40rem;
    margin-top: 8rem;
    -moz-box-shadow:    -5px 0 5px 4px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: -5px 0 5px 4px rgba(0, 0, 0, 0.3);
    box-shadow:         -5px 0 5px 4px rgba(0, 0, 0, 0.3);
    z-index:  3;
    font-family: 'PT sans','Open Sans', sans-serif;
    font-weight: bold;
    font-size: 2rem;
    // height: 100%;
    margin-top: 0 !important;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
}

.sidebar-content{
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 100%; 
  // max-height: 700px;
  font-family: 'PT sans','Open Sans', sans-serif;
  // font-weight: bold;
  /* font-size: 2rem; */
  border-top: 1px solid #2c3e50;
  // overflow: scroll;
  position: relative;
}

p{
  font-family: "Open Sans", sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  // font-size: 1rem;
  // font-weight: 400;
  line-height: 1.5;
}
</style>