<template>
  <b-card :title="`${data.title}`"  :img-src="require(`@/assets/icons/${data.iconFilename}`)" :img-alt="`${data.title}`" img-top>
    <b-card-text>
      {{data.text}}
    </b-card-text>
    <template #footer>
      <b-button size="lg" variant="primary"  :to="`${data.buttonLink}`">{{data.buttonText}}</b-button>
    </template>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CardIcon",
  computed: { 
    ...mapGetters("user",{
      userType:"user_type",
    }) 
  },
  props: {
    data: {}
  }
};
</script>

<style scoped lang="scss">

.card{
  margin: 2rem !important;
  border:none;
} 

img.card-img-top{
  padding: 2rem;
  background-color: $primary;
}

</style>