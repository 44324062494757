<template>
  <b-navbar toggleable="lg" variant="primary" fixed class="navbar-qs">
    <div style="display: flex; flex-direction: column; height: 100%; width: 100%;">
      <div style="display: flex; padding-left: 30px;">
        <router-link to="/">
          <img class="qs-logo" src="@/assets/img/quietseas-logo-blue.png" width="75" height="45" alt="Quietseas">      
        </router-link>
        <span style="margin-left: 20px; font-size: 0.9rem; font-weight: bold; color: #13446e; text-align: left; max-height: 44px; overflow: hidden;">Tool for underwater sound (D11 - MSFD) monitoring and <br>assessment in the Mediterranean and Black Sea regions</span>
        <div style="flex: 1;"></div>
        <b-navbar-nav  class="ml-auto">
          <b-nav-item-dropdown v-if="this.$keycloak.authenticated" right>
            <template  #button-content>
              <em class="navbar-username">{{first_name}} {{last_name}}</em> 
            </template>
            <div  class="dropdown">

              <!-- <p class="dropdown-title ">User</p>
              <b-dropdown-item to="/user-panel" class="dropdown-item">User Panel</b-dropdown-item>
              <hr>
              <p class="dropdown-title ">Impulsive Noise</p>
              <b-dropdown-item :to="{ path: '/data-actions/upload-data' }" class="dropdown-item">Upload Data</b-dropdown-item>
              <b-dropdown-item :to="{ path: '/data-actions/no-data' }" class="dropdown-item">Report No Data</b-dropdown-item>
              <b-dropdown-item  :to="{ path: '/download' }" class="dropdown-item">Download Data</b-dropdown-item>
              <hr> -->
              <b-dropdown-item to="#"  v-on:click="logout" class="dropdown-item">Sign Out</b-dropdown-item>
            </div>
          </b-nav-item-dropdown>
          <b-navbar-nav  v-else right>
            <router-link to="/user-panel"  class="b-nav-item">
              Login
            </router-link>
          </b-navbar-nav>
        </b-navbar-nav>
      </div>


      <div class="" style="height: 28px;"></div>



      <div style="display: flex; flex-direction: row; padding-left: 30px;">
        <router-link to="/"  class="b-nav-item" :class="{'b-nav-item-selected': this.$route.name == 'Home'}">
          Home
          <template v-if="this.$route.name == 'Home'">
            <div class="cachimba-naranja"> </div>
          </template>
        </router-link>
        <!-- <router-link to="/inr-med"  class="b-nav-item" :class="{'b-nav-item-selected': this.$route.name == 'INR-Med'}" @mouseover.native="showModal()" @mouseleave.native="hideModal()" > -->
        <div @click.self="goTo('/inr-med')" class="b-nav-item" :class="{'b-nav-item-selected': this.$route.name == 'INR-Med' || this.$route.name == 'Upload-Data' || this.$route.name == 'No-Data' || this.$route.name == 'Download'}" @mouseover="showModal()" @mouseleave="hideModal()" style="cursor: pointer;">
          Impulsive Noise
          <template v-if="this.$route.name == 'INR-Med' || this.$route.name == 'Upload-Data'  || this.$route.name == 'No-Data' || this.$route.name == 'Download'">
            <div class="cachimba-naranja"> </div>
          </template>
          <div v-if="showINROptions" class="modal-inr" @mouseover="showModal()" @mouseleave="hideModal()">
            <div @click="goTo('/inr-med')" class="modal-inr-options">Map</div>
            <div @click="goTo('/data-actions/upload-data')" class="modal-inr-options">Upload data</div>
            <div @click="goTo('/data-actions/no-data')" class="modal-inr-options">Report no data</div>
            <div @click="goTo('/download')" class="modal-inr-options modal-inr-last-option">Download data</div>
          </div>
        </div>
        <!-- </router-link> -->
        <a  class="b-nav-item" href="https://hub-ppr.sinay.ai/accobams/home">Continuous Noise </a> 
      </div>
    </div>
    <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      
      <b-navbar-nav  class="ml-auto">
        <b-nav-item-dropdown v-if="this.$keycloak.authenticated" right>
          <template  #button-content>
            <em class="navbar-username">{{first_name}} {{last_name}}</em> 
          </template>
          <div  class="dropdown">
             -->
            <!-- <p class="dropdown-title ">User</p>
            <b-dropdown-item to="/user-panel" class="dropdown-item">User Panel</b-dropdown-item>
            <hr>
            <p class="dropdown-title ">Impulsive Noise</p>
            <b-dropdown-item :to="{ path: '/data-actions/upload-data' }" class="dropdown-item">Upload Data</b-dropdown-item>
            <b-dropdown-item :to="{ path: '/data-actions/no-data' }" class="dropdown-item">Report No Data</b-dropdown-item>
            <b-dropdown-item  :to="{ path: '/download' }" class="dropdown-item">Download Data</b-dropdown-item>
            <hr> -->
            <!-- <b-dropdown-item to="#"  v-on:click="logout" class="dropdown-item">Sign Out</b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
        <b-navbar-nav  v-else right>
          <router-link to="/user-panel"  class="b-nav-item">
            Login
        </router-link>
        </b-navbar-nav>
      </b-navbar-nav> -->

  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import FormLogin from "@/components/FormLogin/FormLogin.vue"

export default {
  name: "Navbar",
  components:{
    FormLogin
  },
  props: {
    title: String
  },
  data(){
    return {
      showINROptions: false,
      showINROptionsTimer: null
    }
  },
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      first_name: 'first_name',
      last_name: 'last_name',
      userType: 'user_type',
    }),
     isINRMed(){
        return this.$route.name === "INR-Med"
      },
      isHome(){
        return this.$route.name === "Home"
      }
  },
  // watch: {
  //   showINROptions: function () {
  //     if(this.showINROptions) {
  //       this.showModal()
  //     }
  //     else {
  //       this.hideModal()
  //     }
  //   },
  // },
  methods:{
    showModal: function () {
      if (this.showINROptionsTimer != null) {
        clearTimeout(this.showINROptionsTimer)
      }
      this.showINROptions = true
    },
    hideModal: function () {
      if (this.showINROptionsTimer != null) {
        clearTimeout(this.showINROptionsTimer)
      }
      let globalThis = this   
      this.showINROptionsTimer = setTimeout(() => {
        globalThis.showINROptions = false;
      }, 150);
    },
    goTo: function (url) {
      this.showINROptions = false;
      this.$router.push({path: url})
    },
    logout: function() {
      this.$store
        .dispatch("user/logout")
        .then(() => this.$router.push("/"))
        .catch(err => console.log(err));
    }
  }
};
</script>

<style scoped lang="scss">
.qs-logo{
  // margin-right: -4rem;
  // margin-bottom: 3rem;
}
.qs-logo-text {

}
.qs-menu-options {

}


.b-nav-item {
    margin-right: 2rem; 
    font-weight: 600;
    color: $icon-login !important;
    position:relative;
    // margin-top: 50px;
    height: 0px;
    text-decoration: none !important;
}
.router-link-exact-active {
  text-decoration: none !important;
}

.b-nav-item:hover{
    margin-right: 2rem; 
    font-weight: 600;
    color: $nav-username-color !important;
    position: relative;
    // margin-top: 50px;
    height: 0px;
}


// .b-nav-item:focus{
//     margin-right: 2rem; 
//     font-weight: 600;
//     color: $nav-username-color !important;
//     position: relative;
//     margin-top: 50px;
//     height: 0px;
// }

.b-nav-item-selected{
    margin-right: 2rem; 
    font-weight: 600;
    color: $nav-username-color !important;
    position: relative;
    // margin-top: 50px;
    height: 0px;
}

// .b-nav-item-selected:focus{
//     margin-right: 2rem; 
//     font-weight: 600;
//     color: $nav-username-color !important;
//     position: relative;
//     margin-top: 50px;
//     height: 0px;
// }

// .b-nav-item-selected:not(:focus){
//     margin-right: 2rem; 
//     font-weight: 600;
//     color: $icon-login !important;
//     position:relative;
//     margin-top: 50px;
//     height: 0px;
// }


.dropdown-item{
  padding-left: 1rem;
}

#nav {
  padding: 30px;
}

.form-login{
  margin: 10%;
}

.dropdown{
  width: 15rem;
}

.dropdown-title{
  margin-left: 1rem;
  font-weight: bold;
}

.menu-icon{
  width: 2rem;
  margin-right: 5px;
  vertical-align:middle;
}

.navbar-qs {
  height: $nav-height;
  position: absolute;
  top: 0;
  margin: 0 !important;
  width: 100%;
}

.modal-inr {
  background-color: white;
  position: absolute;
  margin-left: 40px;
  z-index: 1000;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0,0,0,0.2);
  width: 200px;
}
.modal-inr-options {
  color: gray;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-weight: 400;
}
.modal-inr-options:hover{
    font-weight: 600;
    color: $nav-username-color !important;
}
.modal-inr-last-option {
  margin-bottom: 0 !important;
  border-bottom: 0px;
}
</style>
