var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"container-fluid p-0",attrs:{"id":"main"}},[_vm._m(0),_c('div',{staticClass:"section",staticStyle:{"margin-top":"10px"}},[_c('div',{staticClass:"steps-container"},[_c('h2',{staticClass:"actions-header"},[_vm._v("INR Upload Data")]),_c('p',{staticStyle:{"margin-top":"8px"}},[_vm._v("Follow this step-by-step guide to upload data.")]),_c('br'),_c('br'),_vm._m(1),_c('b-button',{staticClass:"button-download-excel",attrs:{"tag":"button","variant":"primary","href":"/files/data_upload_template_INRMED.xlsm","download":""}},[_vm._v(" Download Excel Form ")]),_c('br'),_c('br'),_c('br'),_c('br'),_c('br'),_vm._m(2),_c('br'),_c('br'),_vm._m(3),_c('br'),_c('br'),_vm._m(4),_c('br'),_c('br'),_vm._m(5),_c('FormUploadData'),_c('h3',{staticClass:"divider"},[_vm._v("6")]),_c('p',[_vm._v("If the upload process is successful, the application will show the message “Data uploaded successfully”.")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"thumb-container download-header"},[_c('div',{staticClass:"container-fluid h-100"},[_c('div',{staticClass:"row h-100",staticStyle:{"margin-top":"109px"}},[_c('div',{staticClass:"col-6 mt-5"},[_c('span',{staticClass:"download-header-content"},[_vm._v(" INR Upload Data ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-bottom":"0"}},[_c('span',{staticClass:"step-number"},[_vm._v("1")]),_vm._v(" Download the “Underwater noise register template for the Mediterranean Region” (excel format).")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"step-number"},[_vm._v("2")]),_vm._v(" Fulfil the required fields of the template following the instructions inside.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"step-number"},[_vm._v("3")]),_vm._v(" Transform the excel file in a XML file following the instructions of the template.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"step-number"},[_vm._v("4")]),_vm._v(" Save the XML file generated.")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"step-number"},[_vm._v("5")]),_vm._v(" Upload the generated XML file using the upload box below. (OPTIONAL: If the geometry includes a .sph file, select the zip file containing it in the second selector)")])
}]

export { render, staticRenderFns }