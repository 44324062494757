<template>
    <div id="map" class="map-container">
      <MapSidebar :data="data"/>
    </div>
</template>

<script>
import MapSidebar from "@/components/MapSidebar/MapSidebar.vue"; 
import { mapGetters } from 'vuex'

export default {
  name: "Map",
  data(){
    return {
      map:null
    }
  },
  components: {
    MapSidebar
  }, 
  props: {
    data: {},
  },
  computed: {
    ...mapGetters(
      "map", {
      base_map: 'base_map',
    })
  },
  async mounted(){
    await this.$store.dispatch('map/change_base_map')
    await this.$store.dispatch('map/change_marine_subregions_layer')
    await this.$store.dispatch('map/change_reporting_point_layer')
    await this.$store.dispatch('map/change_reporting_polygon_layer')
    await this.$store.dispatch('map/change_reporting_lines_layer')
    await this.$store.dispatch('map/change_reporting_units_layer')

  },
  methods:{
  }
};
</script>

<style scoped lang="scss">
// .map-container{
//   z-index:0 !important;
//     width: 100%;
    
//     height: calc(1000px - $footer-height);
    
// }
.map-container {
  width: 100%;
  position: absolute;
  bottom: $footer-height;
  top: $nav-height;
}

</style>