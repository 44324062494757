<template>
<div>
    <CardImage v-for="item in data" :data="item" :key="item.id" />
  </div>
</template>

<script>
import CardImage from "@/components/Cards/CardImage.vue";
export default {
  name: "PageSectionCards",
  props: {
    data: {}
  },
  components: {
    CardImage
  }
};
</script>

<style scoped>

div {
    display: flex;
    justify-content: space-evenly
}

</style>