<template>
  <section>
    <div class="grid-flex">
      <div class="col col-text ">
            <div class="Aligner-item">
              <h1><span v-for="(item,index) in data.title" v-bind:key="index">
                <span class="text-color-2" v-if="(item.bold)">{{item.text}}</span>
                <span v-else>{{item.text}}</span>
              </span></h1>
              <p v-for="item in data.text" :key="item.id">{{item}}</p>

              <!-- <button v-if="(data.button.showButton)" type="button" class="btn btn-outline-primary">{{data.button.buttonText}}</button> -->
              <a v-if="(data.button.showButton)" :href="data.button.buttonURL" target="_blank" class="btn btn-success btn-lg">{{data.button.buttonText}}</a>
            </div>
        </div>
        <div class="col col-image col-right"> 
              <span v-if="(data.video.showVideo)">
                  <video-background
                        :src="require(`@/assets/videos/${data.video.videoFile}`)"
                        style="max-height: 600px; height: 120vh; max-width: 120vh;"
                    ></video-background>
              </span>
              <img v-else class="col-image-img" :src="require(`@/assets/img/${data.image.imgFile}`)">  
        </div>
        
    </div>
  </section>
</template>

<script>

export default {
  name: "PageSectionLeftText",
  props: {
    data: {}
  }
};
</script>

<style scoped>
h1{
  font-weight: 700;
  font-size: 3vw;
  align-items: left;
  justify-content: left;
}
p {
  font-weight: 500;
  font-size: 1.3vw;
  align-items: left;
  justify-content: left;
}

.col-text {
  /* height:25em; */
    text-align: left;
    align-items: left;
}
.grid-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  flex-direction: row; 
  -webkit-flex-direction: row;
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: space-around;
}
.col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 2rem;
}
.col-left {
  -webkit-box-ordinal-group:-1;
  -webkit-order: -1;
  -ms-flex-order: -1;
  order: -1;
}
.col-text{
  display: flex;
  align-items: center;
  justify-content: center;
}
.Aligner-item {
  width: 90%;
}
.col-image {
  /* background-size: cover; */
  /* padding: 1rem; */
  background-repeat: no-repeat;
  position:center center;
  min-height: 20em;
  /* margin: 1rem; */
}

.col-image-img{
  background-size: cover;
  /* max-width: 600px; */
  width: 100%;
  height: auto;
}

@media (max-width: 640px) {
  .grid-flex {
    height: 40em;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    flex-direction: column; 
   }
  .col {
    order:vertical;
  }
  .col-left {
    -webkit-box-ordinal-group:0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .col-text div p {
    padding: 1em;
  }
  .Aligner-item {
    width: 90%;
  }
}

.btn-success{
  background-color: rgb(255, 102, 0);
  border-color: rgb(255, 255, 255) !important;
  color:white !important;
}

.btn-success:hover{
  background-color: rgb(247, 146, 78);
}

</style>