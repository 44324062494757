<template>
<footer  :class="{'mt-4': this.$route.name != 'INR-Med'}">
  <div v-if="this.$route.name != 'INR-Med'" class="container pt-4 pb-4">
    <section class="mb-4">
      <h4 style="color: #a6a6a6; font-size: 1.1rem;">© 2023 - QUIETSEAS</h4><br>
      <p style="border-bottom: 1px solid #d5d5d5; padding-bottom: 20px;">
        <img :src="require(`@/assets/img/eu-commission-logo.jpg`)" style="width: 60px; height: auto; margin-right: 20px;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img :src="require(`@/assets/img/CTN-logo.png`)" style="width: 60px; height: auto;">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <img :src="require(`@/assets/img/ACCOBAMS_logo.png`)" style="width: 60px; height: auto; margin-left: 20px;">
      </p>
      
      <p style="font-size: 0.8rem; border-bottom: 1px solid #d5d5d5; padding-bottom: 20px;">This Project has receiven funding from DG Environment of the European Commission within the “DG ENV/MSFD 2020 Call” under Agreement No. 110661/2020/839603/SUB/ENV.C.2</p>
      
    </section>
    <section class="">
      <h5 class="text-uppercase links-title">Links</h5>
      <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-3 mb-4 mb-md-0">
          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://ec.europa.eu/" >European Commision</a>
            </li>
            <li>
              <a href="https://quietseas.eu/contact/">Contact</a>
            </li>
            <li>
              <a href="https://quietseas.eu/news/" >News & Media</a>
            </li>
            <li>
              <a href="https://quietseas.eu/partners-avisory-panel/" >Partners & Advisory Board</a>
            </li>
          </ul>
        </div>
        <div class="col-md-3 mb-4 mb-md-0">
          <!-- <h5 class="text-uppercase links-title">Partners</h5> -->

          <ul class="list-unstyled mb-0">
            <li>
              <a href="https://quietseas.eu/project-work-plan/" >Work Plan</a>
            </li>
            <li>
              <a href="https://quietseas.eu/legal-advice/" >Legal Advice</a>
            </li>
            <li>
              <a href="https://www.ctnaval.com/" >Marine Technology Center (CTN)</a>
            </li>
            <!-- <li>
              <a href="#!" class="text-white">Link 4</a>
            </li> -->
          </ul>
        </div>
        <div class="col-md-3"></div>


      </div>
    </section>
  </div>
  <div v-else class="text-center pt-3 pb-2 pl-5 pr-5 footer-down">
    <span style="font-size: 0.8rem; color: #949494; font-weight: bold;">© 2023 - QUIETSEAS</span>
    <div style="flex: 1;"></div>
    <img class="" src="@/assets/img/eu-commission-logo.jpg"  style="width: auto; height: 35px" alt="EC">
    <img class="ml-5" src="@/assets/img/CTN-logo.png" style="width: 55px; height: auto;" alt="CTN">
    <img class="ml-5" src="@/assets/img/ACCOBAMS_logo.png" style="width: auto; height: 30px;" alt="ACCOBAMS">
  </div>
</footer>
</template>

<script>
export default {
  name: "FooterBig",
  props: {}
};
</script>

<style scoped lang="scss">

footer{
  margin-top: 5px;
  background: #ededed;
}

  .footer-down{
    background-color: rgba(255,255,255,1);
    position: fixed;
    bottom: 0;
    display: flex;
    height: $footer-height;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .links-title{
    text-align: center !important;
  }
.logo-1{
  float: left;
}
.logo-3 {
  float: right;
}
@media (min-width: 768px) {
  .list-unstyled {
      padding-left: 0;
      list-style: none;
      text-align: left !important;
      font-size: 1rem;
  }
  .links-title{
    height: 30px;
    margin-bottom: 0;
    font-size: 1rem;
  }
  a {
    font-size: 0.8rem !important;
  }
}

 
</style>
