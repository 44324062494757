<template>
    <main class="container-fluid p-0" id="main">
      <section class=" thumb-container download-header">
        <div class="container-fluid h-100">
          <div class="row h-100" style="margin-top: 109px;">
            <div class="col-6 mt-5">
                <span class="download-header-content">
                  Download <br/>INR data
                </span>
            </div>
          </div>
        </div><!--container_fluid-->
      </section>
      <section>
        <div class="container-fluid">
          <div class="row mt-5 justify-content-between">
            <div class="col-12">
              <div class="thecontent">
                <p>All the information of the impulsive noise register in the Mediterranean Sea Region is
                  available for download.</p>
                <p>All available formats for downloading (CSV, GML, SHP, KML and GeoJSON) contain geometry
                  information to work in GIS systems. Use the filter to select the information:</p>
              </div>
            </div>
          </div>
  
          <div class="row pb-5 pt-5 justify-content-center">
            <div class="col-6 col-md-4 col-sm-3">
              <hr/>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <ul class="nav nav-pills justify-content-center" id="download-tabs" role="tablist">
                <li class="nav-item custom-nav-pills">
                  <a class="nav-link "   v-bind:class="{ active: panel_show == 'noise_database' }"  v-on:click="panel_show = 'noise_database'" role="tab"
                     aria-selected="true">Noise database</a>
                </li>
                <li class="nav-item custom-nav-pills">
                  <a class="nav-link" v-bind:class="{ active: panel_show == 'indicator_month' }"  v-on:click="panel_show = 'indicator_month'" role="tab"
                     aria-selected="false">D11C1 criteria by month</a>
                </li>
                <li class="nav-item custom-nav-pills">
                  <a class="nav-link" v-bind:class="{ active: panel_show == 'indicator_year' }" v-on:click="panel_show = 'indicator_year'" role="tab"
                     aria-selected="false">D11C1 criteria by year</a>
                </li>
              </ul>
  
              <div class="tab-content thumb-container" id="download-tabs-content">
                <div id="noise_database" v-if="panel_show == 'noise_database'" class=" text-center " role="tabpanel">
                  <div id="download-noise-events">
                    <template>
                      <ul>
                        <li>
                          <FileType></FileType>
                        </li>
                        <li>
                          <GeometryType></GeometryType>
                        </li>
                        <li>
                         <button class="btn-quietseas-primary" v-on:click="downloadNoiseData()">Download noise data</button>
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
                <div id="indicator_month" v-if="panel_show == 'indicator_month'" class=" text-center " role="tabpanel">
                  <div id="download-month-indicator">
                    <template>
                      <ul>
                        <li>
                          <FileType ></FileType>
                        </li>
                        <li>
                          <SpatialScale ></SpatialScale>
                        </li>
                        <li>
                          <button class="btn-quietseas-primary" v-on:click="downloadNoiseMonthIndicator()">Download month indicator</button>
                        </li>
                      </ul>
                    </template>
                  </div>
                </div>
                <div id="indicator_year"  v-if="panel_show == 'indicator_year'" class="text-center" role="tabpanel">
                  <div id="download-year-indicator">
                    <ul>
                      <li>
                        <FileType></FileType>
                      </li>
                      <li>
                        <SpatialScale></SpatialScale>
                      </li>
                      <li>
                        <button class="btn-quietseas-primary" v-on:click="downloadNoiseYearIndicator()">Download year indicator
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </template>
  
  <script>
  import SpatialScale from "@/components/Download/SpatialScale";
  import FileType from "@/components/Download/FileType";
  import GeometryType from "@/components/Download/GeometryType";
  import { downloadData } from "@/services/api.js";
  import { mapGetters } from 'vuex'
  import {
  toaster_success,
  toaster_error,
  toaster_info,
} from "@/services/helper.js";
  
  const FileDownload = require('js-file-download');
  
  export default {
    components: {SpatialScale, FileType, GeometryType},
    data() {
      return {
        panel_show : 'noise_database'
      };
    },
    computed: { 
    ...mapGetters("download",{
      file_type_noise_events_data: 'file_type_noise_events_data',
      geometry_type_noise_events_data: 'geometry_type_noise_events_data',
      file_type_noise_events_indicator: 'file_type_noise_events_indicator',
      spatial_scale_indicator: 'spatial_scale_indicator',
      decimal_separator: 'decimal_separator',
    }),
    },
    methods: {
      downloadNoiseData() {
        let formData = new FormData();
        formData.append('download_type', 'geometry')
        this.downloadMaster("download/download_noise_indicator/",formData)
      },
      downloadNoiseMonthIndicator() {
        let formData = new FormData();
        formData.append('download_type', 'spacial')
        formData.append('is_year', '')
        this.downloadMaster("download/download_noise_indicator/",formData)
      },
      downloadNoiseYearIndicator() {
        let formData = new FormData();
        formData.append('download_type', 'spacial')
        formData.append('is_year', true)
        this.downloadMaster("download/download_noise_indicator/",formData)
      },
      downloadMaster(url,data){
        data.append('filter_format', this.file_type_noise_events_data)
        data.append('spacial_scale', this.spatial_scale_indicator)
        data.append('geometry_type', this.geometry_type_noise_events_data)
        data.append('decimal_separator', this.decimal_separator)
        downloadData(url, data)
        .then((response) => {
          if (response.status == 200)
            FileDownload(response.data, response.headers['content-disposition'].substring(response.headers['content-disposition'].search('filename') + 9))
          else
            toaster_error('Failed to download:' + response.data['detail'])
        })
        .catch((err) => {
          toaster_error('Failed to download:' + err)
        })
      }
    }
  };
  </script>
  
  <style lang="scss">
  .download-header {
    height: 480px;
    background-image: url("~@/assets/img/download_data_dark.png");
    background-size: cover;
    margin-bottom: 100px;
  }
  
  .download-header-content {
    /* position: absolute; */
    max-width: 800px;
    padding: 3rem 2rem 1rem 0;
    // color: $secondary;
    color: white;
    /* text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff,
               1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff; */
    font-size: 3.2em;
    font-family: GothamRndBold, sans-serif;
    font-weight: bold;
  }
  
  .text-align-left {
    text-align: left
  }
  
  .nav-pills {
    position: relative;
    border: none;
    margin: 0 0 3rem !important;
    flex-wrap: nowrap;
  }
  
  .margin-bottom-1rem {
    margin-bottom: 1rem;
  }
  
  .form-select {
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: .25rem;
  }
  
  .btn-basic {
    width: auto;
    font-size: 1rem;
    min-height: initial;
  }
  
  .custom-nav-pills {
    border-radius: 0;
    border-right: 1px solid #4d4d4d;
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    cursor: pointer;
  }
  
  .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #ffffff !important;
    background-color: #2c3e50 !important;
  }
  
  .nav-pills .nav-link {
    border-radius: initial !important;
  }
  
  .nav-pills .nav-item:last-child {
    padding-right: 0;
    border-right: none;
  }
  
  a {
    color: #2c3e50!important;
  }

  .btn-quietseas-primary {
    border-radius: 5px !important;
  }
  
  </style>