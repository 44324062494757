import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { postData } from "@/services/api.js";

const state = () => ({
  status: "",
  showLogin: false,
});

const mutations = {
  status_request(state) {
    state.status = "loading";
  },
  status_ok(state) {
    state.status = "ok";
  },
  status_error(state) {
    state.status = "error";
  },
  showLogin(state, data) {
    state.showLogin = data;
  },
};

const actions = {
  showLogin({ commit }, data) {
    if (data == true) {
      commit("showLogin", data);
    } else {
      commit("showLogin", data);
    }
  },
};
const getters = {
  status: (state) => state.status,
  showLogin: (state) => state.showLogin,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
