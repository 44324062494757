<template>
  <div class="inr-map-view">
      <Map :data="dataToPass" />
      <!-- <MapSidebar :data="dataToPass"/> -->
  </div>
</template>

<script>
import Map from "@/components/Map/map.vue"
import MapSidebar from "@/components/MapSidebar/MapSidebar.vue"; 
export default {
  name: "INRmed",
  components: {
    Map,
    MapSidebar,
  }, 
  data() {
    return {
      dataToPass:{
        maptype: "inr",
        sidebarTitle: "INR Med Options"
      }
    };
  } 
};
</script>

<style scoped lang="scss">

</style>

