import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import SilentCheckSSO from "../views/SilentCheckSSO.vue";
import About from "../views/About.vue";
import Login from "../views/user/Login.vue";
import ResetPassword from "../views/user/ResetPassword.vue";
import ResetPasswordCheckEmail from "../views/user/ResetPasswordCheckEmail.vue";
import NewPassword from "../views/user/NewPassword.vue";
import InrMed from "../views/map/INRmed.vue";
import UserPanel from "../views/UserPanel.vue";
import UploadData from "../views/data-actions/UploadData.vue";
import NoData from "../views/data-actions/NoData.vue";
import Download from "../views/Download.vue";

import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/inr-med",
    name: "INR-Med",
    component: InrMed,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  // {
  //   path: "/user/login",
  //   name: "Login",
  //   component: Login,
  //   meta: {
  //     requiresNotAuth: true,
  //   },
  // },
  // {
  //   path: "/user/reset-password",
  //   name: "ResetPassword",
  //   component: ResetPassword,
  //   meta: {
  //     requiresNotAuth: true,
  //   },
  // },
  // {
  //   path: "/user/check-email",
  //   name: "check-email",
  //   component: ResetPasswordCheckEmail,
  //   meta: {
  //     requiresNotAuth: true,
  //   },
  // },
  // {
  //   path: "/user/new-password",
  //   name: "new-password",
  //   component: NewPassword,
  //   meta: {
  //     requiresNotAuth: true,
  //   },
  // },
  {
    path: "/user-panel",
    name: "User-Panel",
    component: UserPanel,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-actions/upload-data",
    name: "Upload-Data",
    component: UploadData,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/data-actions/no-data",
    name: "No-Data",
    component: NoData,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/download",
    name: "Download",
    component: Download,
    meta: {
      requiresAuth: true,
    },
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresNotAuth)) {
//     if (!store.getters["user/isLoggedIn"]) {
//       next();
//       return;
//     }
//     next("/user-panel");
//   } else {
//     next();
//   }
// });

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     if (store.getters["user/isLoggedIn"]) {
//       next();
//       return;
//     }
//     next("login");
//   } else {
//     next();
//   }
// });

export default router;
