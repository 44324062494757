<template>
  <section>
 <div class="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-primary">
      <div class="col-md-8 p-lg-8 mx-auto my-8">
        <h1 class="display-4 font-weight-normal">{{data.title}}</h1>
        <p v-for="item in data.text" :key="item.id" class="lead font-weight-normal">{{item}}</p>
        <a v-if="(data.showButton)" class="btn btn-outline-light" href="#">{{data.buttonText}}</a>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: "PageSectionFullColorBg",
  props: {
    data: {}
  }
};
</script>

<style scoped>
h1{
  color: white;
  font-weight: 700;
  font-size: 3vw;
  align-items: left;
  justify-content: left;
}
p {
  font-weight: 500;
  color: white;
  font-size: 1.3vw;
  align-items: left;
  justify-content: left;
}

.container {
  max-width: 960px;
}

</style>