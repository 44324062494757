import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VideoBackground from "vue-responsive-video-background-player";
// import VueToastr from "vue-toastr";
import VueToast from "vue-toast-notification";
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";

import { Plugin } from "vue-responsive-video-background-player";
import Vuelidate from "vuelidate";
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import * as localData from "@/services/localData.js";


// import Map from '@arcgis/core/Map';
// import MapView from '@arcgis/core/views/MapView';
import "@arcgis/core/assets/esri/themes/light/main.css";



Vue.config.productionTip = false;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueToast);

Vue.use(Vuelidate);

Vue.use(Plugin);

// Vue.use(VueToastr, {
//   /* OverWrite Plugin Options if you need */
// });

const setUserParams = (userParams) => {
  store.dispatch('user/setUserParams', userParams)
}

Vue.use(VueKeyCloak, {
  config: {
    realm: process.env.VUE_APP_KEYCLOAK_REAL,
    url: process.env.VUE_APP_KEYCLOAK_HTTP_S + '://' + process.env.VUE_APP_KEYCLOAK_HOST + '/auth/',
    clientAuthenticatorType: "client-secret",
    clientId:process.env.VUE_APP_KEYCLOAK_CIIENT_ID,
  },
  init: {
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: window.location.origin + "/inr-med",
    checkLoginIframe: false,
    //redirectUri: window.location.origin  + '/inr-med',
    silentCheckSsoFallback: false,
  },
  onReady: kc => {
    if (Vue.prototype.$keycloak.authenticated) {
      setUserParams(Vue.prototype.$keycloak)
    } 
    router.beforeEach((to, from, next) => {
      if (to.matched.some(record => record.meta.requiresAuth)) {
          if (Vue.prototype.$keycloak.authenticated) {
              return next();
          } else {
              let loginUrl = Vue.prototype.$keycloak.createLoginUrl();
              window.location.replace(loginUrl)
          }
      } else {
          return next();
      }
  })

    new Vue({
      beforeCreate () {
        Vue.prototype.$http = axios
        //axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
        //axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*' 
        axios.defaults.xsrfHeaderName = 'X-CSRFToken'
        axios.defaults.xsrfCookieName = 'csrftoken' 

      },
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
})

