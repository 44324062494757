<template>
  <div class="home">
    <PageSectionHeaderText :data="dataToPass.intro" />
    <!-- <PageSectionCards :data="dataToPass.cards" /> -->
    <!-- <PageSectionFullColorBg :data="dataToPass.section2" /> -->
    <PageSectionLeftText :data="dataToPass.section1" />
    <PageSectionRightText :data="dataToPass.section2" />
    <PageSectionLeftText :data="dataToPass.section3" />
    <PageSectionRightText :data="dataToPass.section4" />
  </div>
</template>

<script>
// // @ is an alias to /src
import Content from "@/assets/text/dataText.JSON"
let dataToPass = Content.home
import Navbar from "@/components/Navbar/Navbar.vue";
import PageSectionHeaderText from "@/components/PageSection/PageSectionHeaderText.vue";
import PageSectionRightText from "@/components/PageSection/PageSectionRightText.vue";
import PageSectionLeftText from "@/components/PageSection/PageSectionLeftText.vue";
import PageSectionCards from "@/components/PageSection/PageSectionCards.vue";
import PageSectionFullColorBg from "@/components/PageSection/PageSectionFullColorBg.vue"


export default {
  name: "Home",
  components: {
    Navbar,
    PageSectionHeaderText,
    PageSectionRightText,
    PageSectionLeftText,
    PageSectionCards,
    PageSectionFullColorBg
  }, 
  data() {
    return {
      dataToPass
      } 
    }
  }  
</script>
