<template>
  <div ref="decimal_separator" id="decimal-separator">
    <template v-if="decimal_separator_boolean">
      <div class="row justify-content-center">
        <div class="col-2 text-align-left">
          <label for="decimal-separator-select">Decimal separator</label>
        </div>
        <div class="col-2 text-align-left">
          <select class="form-select form-select-lg mb-3" id="decimal-separator-select" v-model="decimal_separator">
            <option value="COMMA">Comma (,)</option>
            <option value="SEMICOLON">Semicolon (;)</option>
          </select>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  name: "DecimalSeparator",
  props: ['file_type_noise_events'],
  data() {
    return {
      parent: '',
      child: '',
      decimal_separator_boolean: false
    }
  },
  mounted() {
    this.parent = document.getElementById("download-noise-events");
    this.child = this.$refs.decimal_separator;
    this.decimal_separator_boolean = this.isParentContainingChild(this.parent, this.child);
  },
  computed: {
    decimal_separator: {
        set(data) {this.$store.dispatch("download/change_decimal_separator", data) },
        get() {return this.$store.state.download.decimal_separator },
      },
  },
  watch: {
    file_type_noise_events: function (file_type_noise_events) {
      if (file_type_noise_events === 'CSV') {
        this.$refs.decimal_separator.classList.add('display-block');
      } else {
        this.$refs.decimal_separator.classList.remove('display-block');
      }
    }
  },
  methods: {
    isParentContainingChild: function (parent, child) {
      var node = child.parentNode;
      while (node != null) {
        if (node === parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    }
  }
}

</script>

<style scoped>
.display-block {
  display: block !important;
}

#decimal-separator {
  display: none;
}
</style>