<template>
  <div>
    <div class="row justify-content-center margin-bottom-1rem">
      <div class="col-2 text-align-left">
        <label for="spatial-scale-select">Spatial scale</label>
      </div>
      <div class="col-2 text-align-left">
        <select class="form-select form-select-lg mb-3" v-model="spatial_scale_indicator" id="spatial-scale-select">
          <option value="GCFM">GFCM Block</option>
          <option value="Marine_subregion">Marine Subregion</option>
          <option value="Marine_region">Marine Region</option>
          <option value="All">All</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpatialScale",
  data() {
    return {
    }
  },
  computed: {
    spatial_scale_indicator: {
        set(data) {this.$store.dispatch("download/change_spatial_scale_indicator", data) },
        get() {return this.$store.state.download.spatial_scale_indicator },
      },
  },
}
</script>

<style scoped>

</style>