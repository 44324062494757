<template>
<div class="large-12 medium-12 small-12 cell">
<div>
				<select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" style="margin-right: 4rem;" v-model="form.country">
					<option value="" selected>Select your country</option>
					<option value='ES'>Spain</option>
					<option value='IT'>Italy</option>
					<option value='FR'>France</option>
					<option value='GR'>Greece</option>
					<option value='MT'>Malta</option>
					<option value='SI'>Slovenia</option>
					<option value='MC'>Monaco</option>
					<option value='HR'>Croacia</option>
				</select>
				<select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" v-model="form.year">
					<option value="" selected>Year</option>
					<option v-for="year in years" :key="year" :value="year">{{year}}</option>
				</select>
		</div>
		<div >
			<p>Please select the absence of noise event type </p>

		</div>
		<div style="margin-top: 20px;">
			<div class="form-group form-check-inline" style="margin-right: 4rem;">
				<input class="form-check-input mr-2" type="checkbox" v-model="form.airgunNoise" >
				<label class="form-check-label" for="inlineCheckbox1">Airgun Arrays</label>
			</div>
			<div class="form-check form-check-inline" style="margin-right: 4rem;">
				<input class="form-check-input mr-2" type="checkbox" v-model="form.explosionNoise" >
				<label class="form-check-label" for="inlineCheckbox2">Explosions</label>
			</div>
			<div class="form-check form-check-inline" style="margin-right: 4rem;">
				<input class="form-check-input mr-2" type="checkbox" v-model="form.genericExplicitlyNoise" >
				<label class="form-check-label" for="inlineCheckbox3">Generic Explicitly Impulse Source</label>
			</div>
		</div>
		<div style="margin-top: 15px;">
			<div class="form-check form-check-inline" style="margin-right: 4rem;">
				<input class="form-check-input mr-2" type="checkbox" v-model="form.impactPileNoise" >
				<label class="form-check-label" for="inlineCheckbox4">Impact Pile Driver</label>
			</div>
			<div class="form-check form-check-inline" style="margin-right: 4rem;">
				<input class="form-check-input mr-2" type="checkbox" v-model="form.sonarNoise" >
				<label class="form-check-label" for="inlineCheckbox5">Sonar or Acoustic Deterrents</label>
			</div>
		</div>
		<div style="margin-top: 60px;">
				<div class="form-check form-check-inline">	
					<label class="form-check-label" for="flexCheckDefault">I confirm that the country has no data to report for the selected noise events</label>
					<input class="form-check-input ml-2" type="checkbox" v-model="confirmationSendingNoData" >
				</div>
		</div>
		<div style="margin-top: 20px; margin-bottom: 50px;">
			<button class="btn-quietseas-primary" style="margin-top: 30px;" v-on:click="noDataUpload()">Send Report</button>
		</div>
		<!-- <div class="uploadOk">{{getStatusNoDataMessage}}</div>  -->
		<!-- <div v-if="getStatusUploadNoData == 'success'" class="uploadOk">{{getStatusNoDataMessage}}</div>
		<div v-if="getStatusUploadNoData == 'error'" class="uploadError">{{getStatusNoDataMessage}}</div> -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { generateArrayOfYears } from "@/services/helper.js";

export default {

	name: "FormNoData",
	data() {
    return {
		  years: generateArrayOfYears(),
          confirmationSendingNoData:false,
					form:{
						country:'',
						year:'',
						airgunNoise:false,
						explosionNoise:false,
						genericExplicitlyNoise:false,
						impactPileNoise:false,
						sonarNoise:false,
					}, 
    }
  },
	methods: {
		noDataUpload(){
				const mutationType = "setStatusUploadNoData";
				const url = "upload/no-data-form/";
				const data = this.form;
				this.$store.dispatch('upload_data/submitData', {mutationType, url, data})
				this.defaultNoDataForm();
		},	
      defaultNoDataForm() {
        this.form = {
          country: "",
          year: "",
          airgunNoise: false,
          explosionNoise: false,
          genericExplicitlyNoise: false,
          impactPileNoise: false,
          sonarNoise: false,
        };
        this.confirmationSendingNoData = false;
      },
	},
};
</script>

<style lang = "scss" scoped>


.form-select{
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.select-button {
  background-color: #45a9e0;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}

#file-chosen{
  margin-left: 0.3rem;
  font-size: 1.1rem;
  font-family: GothamRndBook, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-weight: 300;
}

.uploadOk{
		text-align: center;
		color: forestgreen;
    font-size: 1.5rem;
    font-weight: bold;
		margin-top: 20px;
}

.uploadError{
		text-align: center;
		color: Crimson;
    font-size: 1.5rem;
    font-weight: bold;
		margin-top: 20px;
}

</style>