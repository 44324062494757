<template>
  <section>
      <div class="grid-flex">
          <div class="col-12 col-image custom-width-100">
            <span v-if="(data.video.showVideo)">
              <video-background
                        :src="require(`@/assets/videos/${data.video.videoFile}`)"
                        style="position:absolute; max-height: 500px; height: 200vh;"
              ></video-background>
              <div class="col col-text">
                <div class="Aligner-item">
                  <h1>
                    <span v-for="(item,index) in data.title" v-bind:key="index">
                      <!-- <span class="text-color-2" v-if="(item.bold)">{{item.text}}</span> -->
                      <span class="text-color-2" v-if="(item.bold)" style="color: #083359  !important;">{{item.text}}</span>
                      <span v-else>{{item.text}}</span>
                    </span>
                  </h1>
                  <!-- <p v-if="(data.text.p1)">{{data.text.p1}}</p>
                  <img :src="require(`@/assets/img/eu-commission-logo.jpg`)" style="width: 40px; height: 26px;"> -->
                  <!-- <button v-if="(data.button)" type="button" class="btn btn-outline-primary" @click="$router.push(data.button.buttonURL)">{{data.button.buttonText}}</button> -->
                </div>
              </div>
            </span>
            <span v-else><img class="col col-image" :src="require(`@/assets/img/${data.image.imgFile}`)"></span>
          </div>
          <!-- <div class="col col-text">
            <div class="Aligner-item">
              <h1><span v-for="(item,index) in data.title" v-bind:key="index">
                <span class="text-color-2" v-if="(item.bold)">{{item.text}}</span>
                <span v-else>{{item.text}}</span>
              </span></h1>
              <p v-if="(data.text.p1)">{{data.text.p1}}</p>
              <button v-if="(data.button)" type="button" class="btn btn-outline-primary" @click="$router.push(data.button.buttonURL)">{{data.button.buttonText}}</button>
            </div>
          </div> -->
      </div>
  </section>
</template>

<script>
export default {
  name: "PageSectionHeaderText",
  props: {
    data:{},
  }
}
</script>

<style scoped lang="scss">
h1{
  font-weight: 700;
  font-size: 3vw;
  align-items: left;
  justify-content: left;
  color: white;
}
p {
  font-weight: 500;
  font-size: 1.3vw;
  align-items: left;
  justify-content: left;
  color: white;
}

.col-text {
  /* height:25em; */
    text-align: center;
    align-items: center;
    justify-content: center;
}
.grid-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  flex-direction: row; 
  -webkit-flex-direction: row;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  justify-content: space-around;
  -webkit-justify-content: space-around;
}
.col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.col-text{
  margin-top: 100px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.Aligner-item {
  width: 90%;
}
.col-image {
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position:center center;
  min-height: 30em;
  margin: 5rem;
}

@media (max-width: 640px) {
  .grid-flex {
    // height: 40em;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    flex-direction: column; 
   }
  .col {
    order:vertical;
  }
  .col-left {
    -webkit-box-ordinal-group:0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .col-text div p {
    padding: 1em;
  }
  .Aligner-item {
    width: 90%;
  }
}

.custom-width-100 {
  /* width: 100vw !important;
  left: 0 !important; */
  padding: 0; 
  margin: 0;
  margin-top: $nav-height;
  margin-bottom: 80px;
}
</style>