import axios from "axios";
import Vue from "vue";
import {
  toaster_success,
  toaster_error,
  toaster_info,
} from "@/services/helper.js";

const server_path =
  process.env.VUE_APP_HTTP_S +
  "://" +
  process.env.VUE_APP_API_HOST +
  ":" +
  process.env.VUE_APP_API_PORT +
  "/api/";

////////////////// Generic Functions ////////////////////////

export function getData(url) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .get(server_path + url)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

export function postData(url, data) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, data)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

export function putData(url, id, data) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .put(server_path + url + id + "/", data)
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

export function deleteData(url, id) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .delete(server_path + url + id + "/")
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

////////////////// Specific Functions ////////////////////////

export function downloadData(url, data) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  return axios
    .post(server_path + url, data,{
      responseType: 'blob',
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}

export function upload(url, form, file, fileZip) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Vue.prototype.$keycloak.token}`
  url = server_path + url
  const formData = new FormData()

  for (const key in form) {
    formData.append(key, form[key])
  }
  
  formData.append("xml_file", file)
  formData.append('spatial_file', fileZip)

  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      //toaster_success("Data Received Successfuly");
      return res;
    })
    .catch((error) => {
      var res = error;
      res.status = error.response.status;
      toaster_error("Error Uploading Data");
      return res;
    });
}

export function getArcgisToken() {
  var params = {
    'client_id': process.env.VUE_APP_CLIENT_ID,
    'client_secret': process.env.VUE_APP_CLIENT_SECRET,
    'grant_type': "client_credentials"
  }
  return axios
    .post('https://www.arcgis.com/sharing/rest/oauth2/token', params,{
      headers: {
        
      },
    })
    .then((res) => res)
    .catch((error) => {
      if (error.response) {
        return error.response;
      } else {
        return error;
      }
    });
}
