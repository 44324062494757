<template>
    <form @submit.prevent="login">
      <div class="form-input-block">
        <label for="username">UserName</label>
        <input type="text" class="form-control" v-model="username" :id=this.id placeholder="Your username">
      </div>
      <div class="form-input-block">
        <label for="password">Password</label>
        <input type="password" v-model="password" class="form-control" :id=this.id+1 placeholder="Your password">
      </div>
      <div class="button-containter">
          <button type="submit" class="btn btn-primary">Login</button>
          <p class="reset-link"><router-link to="/user/reset-password">Reset Password</router-link></p>
      </div>
    </form>
</template>

<script>
export default {
  name: "Login",
  props: {
    title: String
  },
  data() {
    return {
      username: "",
      password: "",
      id: null // Id que se utiliza para pode repetir el componente con IDs distintas
    };
  },
  methods:{
    login: function() {
      var userdata = {
          username: this.username,
          password: this.password
      }
      this.$store
        .dispatch("user/login", userdata)
        .then(() => this.$router.push("/user-panel"))
        .catch(err => console.log(err));
    }
  },
  mounted () {
    this.id = this._uid
  }
}


</script>

<style scoped>

.form-input-block{
  margin-top: 1rem;
}

.button-containter{
    width: 100%;
    height: 70px;
    margin-top: 1rem;
}

.btn{
  display: inline-block;
}

.reset-link{
  font-size: .8rem;
  display: inline-block;
  margin-left: 1rem;
}

</style>