<template>
  <main class="container-fluid p-0" id="main">
    <section class=" thumb-container download-header">
        <div class="container-fluid h-100">
          <div class="row h-100" style="margin-top: 109px;">
            <div class="col-6 mt-5">
                <span class="download-header-content">
                  INR Upload Data
                </span>
            </div>
          </div>
        </div><!--container_fluid-->
    </section>

    <!-- <UserProfileHeader :data="dataToPass.user"/> -->
    <div class="section" style="margin-top: 10px;">
      
      <div class="steps-container">
            <h2 class="actions-header">INR Upload Data</h2>
            <p style="margin-top: 8px;">Follow this step-by-step guide to upload data.</p>
            <br><br>

            <p style="margin-bottom: 0;"><span class="step-number">1</span> Download the “Underwater noise register template for the Mediterranean Region” (excel format).</p>
            <b-button class="button-download-excel" tag="button" variant="primary" href="/files/data_upload_template_INRMED.xlsm" download> 
                Download Excel Form
            </b-button>
            <br><br><br><br><br>

            <p><span class="step-number">2</span> Fulfil the required fields of the template following the instructions inside.</p>
            <br><br>

            <p><span class="step-number">3</span> Transform the excel file in a XML file following the instructions of the template.</p>
            <br><br>

            <p><span class="step-number">4</span> Save the XML file generated.</p>
            <br><br>

            <p><span class="step-number">5</span> Upload the generated XML file using the upload box below. (OPTIONAL: If the geometry includes a .sph file, select the zip file containing it in the second selector)</p>
              <!-- <div class="container"> -->
                <FormUploadData></FormUploadData>
              <!-- </div> -->
            <h3 class="divider">6</h3>
            <p>If the upload process is successful, the application will show the message “Data uploaded successfully”.</p>
      </div>
    </div>
  </main>
</template>

<script>
// // @ is an alias to /src
import { mapState } from 'vuex' 
import Navbar from "@/components/Navbar/Navbar.vue";
import UserProfileHeader from "@/components/User/UserProfileHeader.vue"
import FormUploadData from '@/components/FormUploadData/FormUploadData.vue';

export default {
  name: "UploadData",
  components: {
    Navbar,
    UserProfileHeader,
    FormUploadData,
  }, 
  data() {
    return {
      // lastState:'default',
      // isLoading: false,
      // fullPage: false,
      // loader: 'spinner',
      dataToPass:{
          user:{
                title: {
                    title1:{
                      text:"Hi ",
                      bold: false
                    },
                    title2:{
                      text: "UserName",
                      bold: true
                    },
                    title3:{
                      text:"! Thanks for uploading data",
                      bold: false
                    },
                }, 
          },
      } 
    }
  },
  computed: mapState({
		statusUi: (state) => state.statusUi,
	}),
  methods :{
  } 
};
</script>

<style scoped lang="scss">

@import "../../styles/main.scss";

p {
  font-weight: 500;
  font-size: 1.1rem;
  align-items: left;
  justify-content: left;
  margin-top: 20px;
}

.section{
    margin-top: 5rem;
}

.divider {
  margin-top: 2rem;
  color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: "";
    display: block;
    height: 0.09em;
    min-width: 30vw;
  }

  &::before {
    background:$primary;
    margin-right: 4vh;
  }

  &::after {
    background:$primary;
    margin-left: 4vh;
  }
}

.download-header {
    height: 480px;
    background-image: url("~@/assets/img/download_data_dark.png");
    background-size: cover;
    margin-bottom: 20px;
  }
.download-header-content {
    /* position: absolute; */
    max-width: 800px;
    padding: 3rem 2rem 1rem 0;
    // color: $secondary;
    color: white;
    /* text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff,
               1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff; */
    font-size: 3.2em;
    font-family: GothamRndBold, sans-serif;
    font-weight: bold;
  }
  .steps-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 100px;
  }
  .step-number {
    font-size: 1.4rem;
    font-weight: 600;
    color: $secondary;
  }
  .button-download-excel {
    background-color: #eaeaea;
  }
  .button-download-excel:hover {
    background-color: #f7f7f7;
  }
</style>
