import axios from "axios";
import * as localData from "@/services/localData.js";
import Vue from "vue";
import { getData } from "@/services/api.js";
import { fliterDates,getDaysInMonth } from "@/services/helper.js";
import { loadModules } from 'esri-loader';


function addLayerMap(getters,layerName,type) {
  var name = layerName
  if (type == 'd11c1') name = 'calculated_d11c1'
  getters['base_map'].add(getters[name + '_layer'])
}

async function setFeatureLayer(getters,commit,layerName,filter,type) {
  var query = "1=1"
  var name = layerName
  if (filter) query = filter
  if (type == 'd11c1') name = 'calculated_d11c1'

  var resToken = await getData('inrmed/get-token/')
  var token = resToken.data

  getters['base_map'].remove(getters[name + '_layer'])
  await loadModules([
    'esri/config',
    'esri/layers/FeatureLayer',
    'esri/widgets/TimeSlider',
    'esri/widgets/Expand',
  ])
  .then(([esriConfig,FeatureLayer,TimeSlider,Expand]) => {
    var featureLayerUrl = getters['layers_url'] + getters['layers_list'][layerName]

    esriConfig.request.interceptors.push({
      urls: featureLayerUrl,
      before: function (params) {
        params.requestOptions.query = params.requestOptions.query || {};
        params.requestOptions.query.token = token;
      },
    });

    var mapLayer = new FeatureLayer({
      url: featureLayerUrl,
      definitionExpression: query,
      outFields:["*"]
    })
    commit("set_"+ name +"_layer", mapLayer)

    getters['base_map'].layers.push(mapLayer);
    

    const timeSlider = new TimeSlider({
      container: "timeSlider",
      playRate: 50,
      stops: {
        interval: {
          value: 1,
          unit: "days"
        }
      }
    })



  })
  
  let searchField = ''
  if(layerName == 'reporting_point' || layerName == 'reporting_units' || layerName == 'reporting_lines' || layerName == 'reporting_polygon'){
    searchField = 'data_entry_point_id'
    getters[layerName + '_layer'].popupTemplate = {
      title: type + ": {source_event}",
      content: [
        {
          type: "fields",
          fieldInfos: [
            { fieldName: "country"},
            { 
              fieldName: "preparation_date",   
              format: {
                dateFormat: 'short-date'
              }
            },
            { fieldName: "organization"},
            { fieldName: "data_entry_point_id"},
            { 
              fieldName: "start_date",
              format: {
                dateFormat: 'short-date'
              }
            },
            { 
              fieldName: "end_date",
              format: {
                dateFormat: 'short-date'
              }
            },
            { fieldName: "latitude" },
            { fieldName: "longitude" },
            { fieldName: "geometry_type" },
            { fieldName: "source_event" },
            { fieldName: "value_code" },
            { fieldName: "sound_mitigation_bool" },
            { fieldName: "noise_mitigation" },
            { fieldName: "sound_measurement_bool" },
            { fieldName: "sel" },
            { fieldName: "lpeak" },
            { fieldName: "distance_to_pile" },
            { fieldName: "type_hammer" },
            { fieldName: "max_energy" },
            { fieldName: "source_spectra" },
            { fieldName: "duration" },
            { fieldName: "directivity" },
            { fieldName: "source_depth" },
            { fieldName: "remarks" },
            { fieldName: "marine_region" }
          ]
        }
      ]
    }
  }else if (layerName == 'GFCMGridbyYear' || layerName == 'GFCMGridbyMonth' || layerName == 'marineSubregionsbyMonth' || layerName == 'marineSubregionsbyYear' || 
            layerName == 'marineSubregionsbyMonthArea' || layerName == 'marineSubregionsbyYearArea' || layerName == 'marineRegionsbyMonth' || layerName == 'marineRegionsbyYear' || layerName == 'marineRegionsbyMonthArea' || layerName == 'marineRegionsbyYearArea') {
    searchField = 'sector_code'
    getters['calculated_d11c1_layer'].popupTemplate = {
      title: layerName,
      content: [
        {
          type: "fields",
          fieldInfos: [
            { fieldName: "year"},
            { fieldName: "month"},
            { fieldName: "d11c1_days"},
            { fieldName: "d11c1_area_percentage"},
            { fieldName: "sector_code"},
            // { fieldName: "Shape_Length" },
            // { fieldName: "Shape_Area" },
          ]
        }
      ]
    }
    
  } else {
    searchField = ''
  }
  if (searchField != ''){
    let source =
    {
      layer: getters[layerName + '_layer'],
      searchFields: [searchField],
      displayField: searchField,
      exactMatch: false,
      outFields: ["*"],
      name: layerName,
      placeholder: searchField,
      maxResults: 6,
      maxSuggestions: 6,
      suggestionsEnabled: true,
      minSuggestCharacters: 0
    }
    getters['search_widget'].viewModel.sources.push(source)
  }
}


const state = () => ({
  layers_url: 'https://services8.arcgis.com/sMKixG2iQxA2ccDt/arcgis/rest/services/Queitmed_Mediterranean_Noise_Register_V2/FeatureServer/',
  base_map: '',
  base_view: '',
  search_widget: '',
  layers_list: {
    reporting_point: 22,
    reporting_units: 1,
    reporting_lines: 2,
    reporting_polygon:25,
    GFCMGridbyMonth: 13,
    GFCMGridbyYear: 4,
    marineSubregionsbyMonth: 5,
    marineSubregionsbyYear: 6,
    marineRegionsbyMonth: 7,
    marineRegionsbyYear: 8,
    GFCMgrid: 24,
    criteriaBlocks: 21,
    marineSubregionsbyMonthArea: 11,
    marineSubregionsbyYearArea: 12,
    marineRegionsbyMonthArea: 14,
    marineRegionsbyYearArea: 15,
    marine_subregions:16,
  },
  reporting_point_layer: '',
  reporting_units_layer: '',
  reporting_lines_layer: '',
  reporting_polygon_layer: '',
  calculated_d11c1_layer: '',
  GFCMgrid_layer: '',
  criteriaBlocks_layer: '',
  marine_subregions_layer: '',


});

const mutations = {
  set_base_map(state,map) {
    state.base_map = map;
  },
  set_search_widget(state,widget) {
    state.search_widget = widget;
  },
  set_base_view(state,view) {
    state.base_view = view;
  },
  /* data = [layerName, status] */
  set_layer_list(state,data) {
    state.layers_list[data[0]] = data[1];
  },
  set_reporting_point_layer(state,layer) {
    state.reporting_point_layer = layer;
  },
  set_reporting_units_layer(state,layer) {
    state.reporting_units_layer = layer;
  },
  set_reporting_lines_layer(state,layer) {
    state.reporting_lines_layer = layer;
  },
  set_reporting_polygon_layer(state,layer) {
    state.reporting_polygon_layer = layer;
  },
  set_calculated_d11c1_layer(state,layer) {
    state.calculated_d11c1_layer = layer;
  },
  set_GFCMgrid_layer(state,layer) {
    state.GFCMgrid_layer = layer;
  },
  set_criteriaBlocks_layer(state,layer) {
    state.criteriaBlocks_layer = layer;
  },
  set_marine_subregions_layer(state,layer) {
    state.marine_subregions_layer = layer;
  },
 
};

const actions = {
  async change_base_map({ commit }, data) {

      await loadModules([
        'esri/config',
        'esri/Map',
        'esri/views/MapView',
        'esri/widgets/Legend',
        'esri/widgets/Search',
        'esri/widgets/LayerList',
        'esri/widgets/Expand',
        'esri/symbols/Font',
        'esri/widgets/TimeSlider'
      ])
      .then(([esriConfig,Map,MapView,Legend,Search,LayerList,Expand,Font,TimeSlider]) => {
        
        // create map with the given options at a DOM node w/ id 'mapNode'
        esriConfig.apiKey = "AAPK3bdee4bc57bf4bd6a41358f6d7ebf00djlpDdUQaJyuIXLyGzSjedOlvD9_8M3KXDI9EkPy0MKbknYohw3T-V9C8tpNH-JL6";
        let webMap = new Map({
          basemap: "hybrid" 
          //basemap: "arcgis-topographic" 
        });

        let view = new MapView({
          container: "map",
          map: webMap,
          zoom: 6,
          center: [12.623,38.959],
          // Since there are many elements, it is best to dock the popup so
          // the elements display better rather than have to scroll through them all.
          popup: {
            alignment: 'auto',
            // dockEnabled: true,
            // dockOptions: {
            //   buttonEnabled: false,
            //   breakpoint: false
            // }
            }
        });

        let search_widget = new Search({
          view: view,
          sources:[],
          searchAllEnabled: false,
          locationEnabled: false
        });

        view.ui.add(search_widget, {
          position: "top-left",
        });

        let layerList = new LayerList({
          view: view
        })
        let layerListExpand = new Expand({
          expandIconClass: "esri-icon-layer-list",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
          // expandTooltip: "Expand LayerList", // optional, defaults to "Expand" for English locale
          view: view,
          content: layerList
        })

        view.ui.add(layerListExpand, "bottom-right")

        var leyend =  new Legend({ view: view })
        let leyendExpand = new Expand({
          expandIconClass: "esri-icon-legend",  // see https://developers.arcgis.com/javascript/latest/guide/esri-icon-font/
          // expandTooltip: "Expand LayerList", // optional, defaults to "Expand" for English locale
          view: view,
          content: leyend
        })

        view.ui.add(leyendExpand, "bottom-left")



        commit("set_search_widget", search_widget)
        commit("set_base_map", webMap)
        commit("set_base_view", view)
      })
      .catch(err => {
        // handle any script or module loading errors
        console.error(err);
      });
  },
  async change_reporting_point_layer({ commit, getters }, filter) {
    await setFeatureLayer(getters,commit,'reporting_point','','Point')
    addLayerMap(getters,'reporting_point','data')
  },
  async change_reporting_polygon_layer({ commit, getters }, filter) {
    await setFeatureLayer(getters,commit,'reporting_polygon','','polygon')
    addLayerMap(getters,'reporting_polygon','data')
  },
  async change_reporting_lines_layer({ commit, getters }, filter) {
    await setFeatureLayer(getters,commit,'reporting_lines','','lines')
    addLayerMap(getters,'reporting_lines','data')
  },
  async change_reporting_units_layer({ commit, getters }, filter) {
    await setFeatureLayer(getters,commit,'reporting_units','','Polygon')
    addLayerMap(getters,'reporting_units','data')
  },
  async change_marine_subregions_layer({ commit, getters }) {
    await setFeatureLayer(getters,commit,'marine_subregions','','')
    addLayerMap(getters,'marine_subregions','data')
  },
  async change_calculated_d11c1_layer_layer({ commit,getters, rootGetters }, filter) {
    var monthList= ["January","February","March","April","May","June","July",
                    "August","September","October","November","December"]

    let unit_selected = rootGetters['inr/unit_selected'] // Days or Area
    let spacial_scales_selected = rootGetters['inr/spacial_scales_selected'] // GFCM or Subregion or Region
    let date_selected = rootGetters['inr/date_selected'] // date
    let temporal_selected = rootGetters['inr/temporal_selected'] // year or month
    let layerName = ''

    if (date_selected && spacial_scales_selected  && unit_selected && temporal_selected){
      let temporal_type = temporal_selected.charAt(0).toUpperCase() + temporal_selected.slice(1);
      switch (spacial_scales_selected) {
        case 'GFCM':
            layerName = 'GFCMGridby' + temporal_type
          break;
        case 'Subregion':
            layerName = 'marineSubregionsby' + temporal_type
          break;
        case 'Region':
            layerName = 'marineRegionsby' + temporal_type
          break;
      }


      let year = date_selected.getFullYear()
      let query = 'year = ' + year
      let query_data = ''
      if (temporal_selected == 'month'){
        let month = monthList[date_selected.getMonth()]
        query = query + " AND  month = '" + month + "'"
        let days_month = getDaysInMonth(year,date_selected.getMonth() + 1)
        let month_number = ("0" + (date_selected.getMonth() + 1)).slice(-2);
        query_data = fliterDates(year,month_number,days_month)
      }else {
        query_data = fliterDates(year)
      }
      console.log(query_data)



      if (unit_selected == 'Area'){
        layerName = layerName + 'Area'

        await setFeatureLayer(getters,commit,layerName,query,'d11c1')
        addLayerMap(getters, layerName,'d11c1')
        await setFeatureLayer(getters,commit,'criteriaBlocks',query,'')
        addLayerMap(getters, 'criteriaBlocks','data')
        await setFeatureLayer(getters,commit,'GFCMgrid','','')
        addLayerMap(getters, 'GFCMgrid','data')
      }else {
        getters['base_map'].remove(getters['criteriaBlocks_layer'])
        getters['base_map'].remove(getters['GFCMgrid_layer'])
        await setFeatureLayer(getters,commit,layerName,query,'d11c1')
        addLayerMap(getters, layerName,'d11c1')
      }

      var layer_name_data = ['reporting_point','reporting_units','reporting_lines','reporting_polygon']
      layer_name_data.forEach(layer_name => {
        getters[layer_name + '_layer'].definitionExpression = query_data
        getters['base_map'].remove(getters[layer_name + '_layer'])
        getters['base_map'].add(getters[layer_name + '_layer'])
      })

    }
  },
  
};

const getters = {
    base_map: (state) => state.base_map,
    base_view: (state) => state.base_view,
    search_widget: (state) => state.search_widget,
    layers_url: (state) => state.layers_url,
    layers_list: (state) => state.layers_list,
    reporting_point_layer: (state) => state.reporting_point_layer,
    reporting_units_layer: (state) => state.reporting_units_layer,
    reporting_lines_layer: (state) => state.reporting_lines_layer,
    reporting_polygon_layer: (state) => state.reporting_polygon_layer,
    calculated_d11c1_layer: (state) => state.calculated_d11c1_layer,
    GFCMgrid_layer: (state) => state.GFCMgrid_layer,
    criteriaBlocks_layer: (state) => state.criteriaBlocks_layer,
    marine_subregions_layer: (state) => state.marine_subregions_layer,
}


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
