<template>
  <div>
    <UserProfileHeader />
    <div v-if="dataToPass.cards.actions" class="section">
      <h2>Noise Data Related Actions</h2>
      <div class="actions-deck">
        <b-card-group deck>
          <CardIcon v-for="(card, index) in dataToPass.cards.actions" v-bind:key="index" :data="card"/>
        </b-card-group>
      </div>
    </div>
  </div>
</template>

<script>
// // @ is an alias to /src
import Content from "@/assets/text/dataText.JSON"
let dataToPass = Content.userPanel
import Navbar from "@/components/Navbar/Navbar.vue";
import UserProfileHeader from "@/components/User/UserProfileHeader.vue"
import CardIcon from "@/components/Cards/CardIcon.vue"

import { mapGetters } from 'vuex'

export default {
  name: "UserPanel",
  components: {
    Navbar,
    UserProfileHeader,
    CardIcon
  }, 
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      userType:"user_type",
      isTechnician: "is_technician",
      isManager: "is_manager",
      isCoordinator: "is_coordinator",
    }) 
  },
  data() {
    return {
      dataToPass
    }
  }  
};
</script>

<style scoped lang="scss">

@import "../styles/main.scss";
.section{
    margin-top: 5rem;
}

.card-deck {
  max-width: 60vw;
} 

.actions-deck{
  display: flex;
  justify-content: space-evenly;
}
</style>
