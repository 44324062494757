<template>
  <div>
											<div class="large-12 medium-12 small-12 cell" style="text-align: left;">
                              <label >Select  XML File
                                <input type="file" id="file" ref="file" v-on:blur="handleFileUpload()"/>
                              </label>
                              <br>
                              <label class="mt-1" >Select Spatial File (Optional zip file with .sph)
                                <input type="file" id="fileSpatial" ref="fileZip" v-on:blur="handleFileUploadZip()"/>
                              </label>
                              <!-- <button v-if="status == 'ok'" class="btn-center mt-3 btn-quietseas-primary" v-on:click="fileUpload()" style="margin: 0; border-radius: 5px;;">Submit File(s)</button> -->
                              <button v-if="status == 'ok'" class="btn-center mt-3 btn-quietseas-primary" v-on:click="fileCheckUpload()" style="margin: 0; border-radius: 5px;;">Submit File(s)</button>
                              <PulseLoader v-else  color="#f60"></PulseLoader>
                              <div v-if="getStatusUploadFile == 'success'" class="uploadOK">{{getStatusFileMsg}}</div>
                              <div v-if="getStatusUploadFile == 'error'" class="uploadError">{{getStatusFileMsg}}</div>
											</div>

                      <div class="modal " :class="{ 'd-block': showOverwrite }" tabindex="-1" role="dialog" style="display: none;">
                        <div class="modal-dialog modal-lg" role="document">
                          <div class="modal-content">
                            <div class="modal-header">
                              <h5 class="modal-title">Warning: Data overwrite </h5>
                              <button type="button" class="close" @click="showOverwrite = false">
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              One or more records with the following identifiers already exist in the database: {{ repeat_data_ids.join(", ") }}
                              <br><br>
                              Do you want to overwrite the records?
                              <br><br>
                              <b>Note:</b> The location/geometry of the record will not be overwritten. <br>
                              If you need to change the location/geometry of any record, please contact technical support.
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" @click="showOverwrite = false">Close</button>
                              <button type="button" class="btn btn-primary" v-on:click="fileUpload()">Overwrite</button>
                            </div>
                          </div>
                        </div>
                      </div>

	</div>
</template>

<script>
import { mapState,mapGetters } from 'vuex'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { upload } from "@/services/api";

export default {
	name: "FormUploadData",
  components: {
    PulseLoader
  },
	data() {
    return {
          file: '',
          fileZip: '',
          repeat_data_ids: [],
          showOverwrite: false
    }
  },
	methods: {
    fileUpload(){
        const url = "upload/";
        const mutationType = "setStatusUploadFile"; 
        const data = null;       
        const file = this.file;
        const fileZip = this.fileZip;
        this.showOverwrite = false
        this.$store.dispatch('upload_data/submitData', {mutationType, url, data, file,fileZip})

    },
    fileCheckUpload(){
      upload("upload/check/", null, this.file, null)
      .then((res) => {
        this.repeat_data_ids = res.data.repeat_data_entry_point_id
        if(this.repeat_data_ids.length == 0){
         this.fileUpload()
        }else {
          this.showOverwrite = true
        }
      })
    },
		handleFileUpload(){
        this.$store.dispatch('upload_data/resetMessages')
        this.file = this.$refs.file.files[0];
			},
      handleFileUploadZip(){
        this.$store.dispatch('upload_data/resetMessages')
        this.fileZip = this.$refs.fileZip.files[0]
			},
	},
  computed: mapState({
		getStatusUploadFile: (state) => state.upload_data.statusUploadFile,
    getStatusFileMsg: (state) => state.upload_data.fileMsg,
    status: (state) => state.upload_data.status
	})
};
</script>

<style lang = "scss" scoped>


.form-select{
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.select-button {

  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  margin-bottom: 1rem; 
}

#file-chosen{
  margin-left: 0.3rem;
  font-size: 1.1rem;
  font-family: GothamRndBook, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-weight: 300;
}

.uploadOK{
  color: forestgreen;
  font-size: 1.5rem;
  font-weight: bold;
}

.uploadError{
  color: Crimson;
  font-size: 1.5rem;
  font-weight: bold;
}

</style>