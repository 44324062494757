<template>
  <div class="section">
    <h2>Check your email to reset your password</h2>
    <div class="section">
      <p>Please check your email and follow the instructions to reset your password.</p>
      <p>You can close this tab.</p>
      <img :src='require(`@/assets/icons/email-by-freepik.png`)' alt="email">
    </div>
    <p class="login-link">Back to <b-link v-on:click="goToRegister">Register Page</b-link> or <b-link v-on:click="goToLogin">Login</b-link></p>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar/Navbar.vue";
import { mapGetters } from 'vuex'

export default {
  name: "ResetPasswordCheckEmail",
  components: {
    Navbar,
  }, 
  computed: { 
    ...mapGetters("ui",{
      showLogin:'showLogin'
    }) 
  },
  methods:{
    goToLogin: function() {
      this.$store
        .dispatch("ui/showLogin", true)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    },
    goToRegister: function() {
      this.$store
        .dispatch("ui/showLogin", false)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    }
  },
};
</script>

<style scoped>
.section{
    margin-top: 5rem;
}

</style>