<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-2 text-align-left">
        <label for="file-type-select">File type</label>
      </div>
      <div class="col-2 text-align-left">
        <select class="form-select form-select-lg mb-3" v-model="file_type_noise_events_data" id="file-type-select">
          <option value="GML">GML</option>
          <option value="CSV">CSV</option>
          <option value="KML">KML</option>
          <option value="GeoJSON">GeoJSON</option>
          <option value="ESRI Shapefile">SHP</option>
        </select>
      </div>
    </div>
      <DecimalSeparator :file_type_noise_events="file_type_noise_events_data"></DecimalSeparator>
  </div>
</template>

<script>

import DecimalSeparator from "@/components/Download/DecimalSeparator";

export default {
  name: "FileType",
  components: {DecimalSeparator},
  data() {
    return {
      file_type_noise_events: ''
    }
  },
  computed: {
    file_type_noise_events_data: {
        set(data) {this.$store.dispatch("download/change_file_type_noise_events_data", data) },
        get() {return this.$store.state.download.file_type_noise_events_data },
      },
  },
  watch: {
    // file_type_noise_events: function (file_type_noise_events) {
    //   this.$emit('child-file-type-noise-events', file_type_noise_events);
    // }
  }
}
</script>

<style scoped>

</style>