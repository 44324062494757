<template>
  <div class="inr-sidebar">
    <div class="sidebar-block">
      <h3>Spatial Scale</h3>
      <b-form-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="SpatialscaleSelected"
          :options="SpatialscaleTypes"
          :aria-describedby="ariaDescribedby"
          button-variant="primary"
          name="buttons-1"
          buttons
        ></b-form-radio-group>
      </b-form-group>
    </div>
    <div class="sidebar-block">
      <h3>Units of Measurement</h3>
      <b-form-checkbox-group v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="UnitSelected"
          :options="UnitTypes"
          :aria-describedby="ariaDescribedby"
          button-variant="primary"
          name="buttons-1"
          buttons
        ></b-form-radio-group>
      </b-form-checkbox-group>
    </div>
    <div class="sidebar-block">
      <h3>Temporary Scale</h3>
      <div class="check-input">
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            id="temporaryScale"
            v-model="TemporalSelected"
            :options="TemporalTypes"
            :aria-describedby="ariaDescribedby"
            name="radio-options" 
            button-variant="primary"
            buttons
          ></b-form-radio-group>
        </b-form-group>
      </div>
      <div class="datepicker-block">
        <datepicker
          id="datepicker"
          v-model="dateSelected"
          v-on:change="changeSelectAndFormat(true)"
          :minimum-view="TemporalSelected"
          :bootstrap-styling="true"
          :format="TemporalFormat"
          :disabledDates="disabledDates"
          calendar-button
          clear-button
          input-class="input-class"
          wrapper-class="wrapper-class"
        ></datepicker>
      </div>
    </div>
    <div class="sidebar-block">
      <b-button block variant="success" size="lg" v-on:click="filterData()" style="margin-top: 3rem; background-color: #ff6600; border-color: #ffffff !important "
        >Filter Data</b-button
      >
      <b-button
        variant="outline-secondary"
        style="margin-top: 1rem; background-color:#cdcdcd; border-color: #cdcdcd color:#cdcdcd !important"
        v-on:click="resetState()"
        >Clear Filters</b-button
      >
    </div>
  </div>
</template>

<script>
  import Datepicker from "vuejs-datepicker";
  import * as lang from "vuejs-datepicker/src/locale";
  import {getData} from "../../../services/api"

  export default {
    name: "InrSidebar",
    components: {
      Datepicker,
    },
    props: {},
    data() {
      return {
        UnitTypes: [
          { text: "D11C1 Days", value: "Days" },
          { text: "D11C1 Area", value: "Area" },
        ],
        SpatialscaleTypes: [
          { text: "Region", value: "Region" },
          { text: "Subregion", value: "Subregion" },
          { text: "GFCM Grid", value: "GFCM" },
        ],
        TemporalTypes: [
          { text: "Years", value: "year" },
          { text: "Months", value: "month" },
        ],
        TemporalFormat: "yyyy",
        dateSelected: null,
        // TemporalSelected: "month",
        activeYears : [],
        activeMonths: [],
        disabledDates: {},
      };
    },
    watch: {
      dateSelected: function() {
        this.$store.dispatch("inr/changeDate", this.dateSelected);
      },
      SpatialscaleSelected: function () {
        if(this.SpatialscaleSelected  == 'GFCM'){
          this.UnitTypes = [
            { text: "D11C1 Days", value: "Days" },
          ]
        }else {
          this.UnitTypes = [
            { text: "D11C1 Days", value: "Days" },
            { text: "D11C1 Area", value: "Area" },
          ]
        }
      },
      TemporalSelected: function() {
        if (this.TemporalSelected == "year") {
          this.TemporalFormat = "yyyy";
        }
        if (this.TemporalSelected == "month") {
          this.TemporalFormat = "MMMM / yyyy";
        }
      },
    },
    created: function () {
      this.getActiveDates("upload/inr-dates/")
    },
    computed: {
      SpatialscaleSelected: {
        set(data) {this.$store.dispatch("inr/changeSpacial", data) },
        get() {return this.$store.state.inr.SpatialscaleSelected },
      },
      UnitSelected: {
        set(data) { this.$store.dispatch("inr/changeUnit", data) },
        get() { return this.$store.state.inr.UnitSelected},
      },
      TemporalSelected: {
        set(data) { this.$store.dispatch("inr/changeTemporal", data) },
        get() { return this.$store.state.inr.TemporalSelected},
      },
    },
    methods: {
      async filterData() {
        await this.$store.dispatch('map/change_calculated_d11c1_layer_layer')
      },
      resetState() {
        (this.dateSelected = null),
        (this.TemporalSelected = "year"),
        this.$store.dispatch("inr/resetState");
      },
      changeTemporalFormat() {
        if (this.TemporalSelected == "year") {
          this.TemporalFormat = "yyyy";
        }
        if (this.TemporalSelected == "month") {
          this.TemporalFormat = "MMMM / yyyy";
        }
      },
      getActiveDates(url){
        getData(url)
          .then((response) => {
            this.activeYears = response.data[0]
            this.activeMonths = response.data[1]
            let thisGlobal = this
            this.disabledDates = {
              customPredictor(date) {
                let year = date.getYear();
                let month = date.getMonth();
                let disableYear = false
                let disableMonth = false
                thisGlobal.activeYears.map((item, i) => {
                  if (thisGlobal.TemporalSelected == "year" && item == year){
                    disableYear = true 
                  }
                  if (thisGlobal.TemporalSelected == "month" && item == year && thisGlobal.activeMonths[i] == month){
                    disableMonth = true 
                  }
                })
                if (disableYear || disableMonth){
                  return false
                } else return true
              }
            }
          })
      },
    }
  }
</script>

<style scoped lang="scss">
  .inr-sidebar {
    overflow-y: hidden !important;
    z-index: 3;
    margin-top: 0rem;
  }
  .sidebar-block {
    padding: 1rem 2rem 1rem 2rem;
    // border-bottom: 1px solid $primary;
    border-bottom: 1px solid #d5dde6;
    
  }

  p {
    font-family: "Open Sans", sans-serif, Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 3px;
  }
  .pressure-check {
    font-size: 1rem !important;
    
  }
  .range-value {
    display: inline-block;
    width: 90px;
    margin-left: 1rem;
  }
  .select-input {
    width: 400px;
    margin-left: 20px;
  }
  .select-input-date {
    width: 200px;
    margin-left: 20px;
  }
  .check-input {
    font-size: 1rem !important;
    margin-left: 0px;
    
  }
  .input-inline-block {
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    margin-top: 1rem;
    
  }

  .datepicker-block {
    color: black !important; 
    font-size: 1rem;
    line-height: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  h3 {
    font-size: 1rem;
    color: $secondary;
  }
  // .cell.selected{
  //   background: pink !important;
  // }
</style>
