<template>
  <div class="section">
    <h2>Choose a new password for you QUIETSEAS account</h2>
    <div class="forms-block">
      <form @submit.prevent="resetNewPassword">
      <div class="form-input-block">
        <label for="newPassword">Choose a new Password</label>
        <input type="password" 
            required 
            :v="$v.newPassword" 
            class="form-control" 
            v-model="newPassword" 
            id="newPassword" 
            placeholder="Your new password">
        <p v-if="!$v.newPassword.minLength" class="error-label"
          >Passwords must be at least 6 characters long.
        </p>
        <label for="newPassword">Repeat the new password</label>
        <input 
            type="password" 
            required 
            :v="$v.newPassword2" 
            class="form-control" 
            v-model="newPassword2" 
            id="newPassword2" 
            placeholder="New password again">
      </div>
        <p v-if="!$v.newPassword2.sameAsPassword" class="error-label"
          >Passwords do not match.
        </p>
      <div class="button-containter">
          <button type="submit" class="btn btn-primary">Save New Password</button>
      </div>
    </form>
    </div>
    <p class="login-link">Back to <b-link v-on:click="goToRegister">Register Page</b-link> or <b-link v-on:click="goToLogin">Login</b-link></p>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar/Navbar.vue";
import { mapGetters } from 'vuex'
import { required, sameAs, minLength } from 'vuelidate/lib/validators'

export default {
  name: "newPassword",
  components: {
    Navbar,
  }, 
  data() {
    return {
      token: this.$route.query.token,
      newPassword: "",
      newPassword2: "",
    };
  },
  computed: { 
    ...mapGetters("ui",{
      showLogin:'showLogin'
    }) 
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(6)
    },
    newPassword2: {
      required,
      sameAsPassword: sameAs('newPassword')
    }
  },
  methods:{
    resetNewPassword: function() {
      this.$v.newPassword.$touch();
      this.$v.newPassword2.$touch();
      if (!this.$v.newPassword.minLength || !this.$v.newPassword2.sameAsPassword) {
        console.log("Error")
        return;
      } else {
      var userdata = {
          "password": this.newPassword,
          "token": this.token
      }
      this.$store.dispatch("ui/showLogin", true)
      this.$store
        .dispatch("user/resetPasswordConfirm", userdata) 
        .then(() => this.$router.push("/user/login"))
        .catch(err => console.log(err));
      }
    },
    goToLogin: function() {
      this.$store
        .dispatch("ui/showLogin", true)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    },
    goToRegister: function() {
      this.$store
        .dispatch("ui/showLogin", false)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    }
  },
};
</script>

<style scoped>
.section{
    margin-top: 5rem;
}

.forms-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.form-input-block{
  width: 20vw;
}

.button-containter{
    width: 100%;
    height: 70px;
    margin-top: 1rem;
}

label{
  margin-top: 1rem;
}

.error-label{
  color:red;
  font-size: 0.8rem;
}
</style>