import { render, staticRenderFns } from "./FileType.vue?vue&type=template&id=650c60d0&scoped=true&"
import script from "./FileType.vue?vue&type=script&lang=js&"
export * from "./FileType.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "650c60d0",
  null
  
)

export default component.exports