<template>
  <div class="section">
    <div class="forms-block">
    <div v-if="showLogin==true" class="form"><h2>Login</h2><FormLogin /></div>
    <div v-if="showLogin==false" class="form"><h2>Register</h2><FormRegister /></div>
    </div>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar/Navbar.vue";
import FormLogin from "@/components/FormLogin/FormLogin.vue"
import FormRegister from "@/components/FormRegister/FormRegister.vue"
import { mapGetters } from 'vuex'


export default {
  name: "Login",
  computed: { 
    ...mapGetters("ui",{
      showLogin:'showLogin'
    }) 
  },
  components: {
    Navbar,
    FormLogin,
    FormRegister,
  }, 
};
</script>

<style scoped>
.section{
    margin-top: 5rem;
}

.forms-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.form{
  min-width: 400px;
}
</style>