<template>
    <form @submit.prevent="register">
      <div class="form-input-block">
        <label for="username">UserName</label>
        <input type="text" class="form-control" v-model="username" id="usernameReg" placeholder="Enter username">
      </div>
      <div class="form-input-block">
        <label for="email">Email</label>
        <input type="email" class="form-control" v-model="email" id="emailReg" placeholder="Enter email">
      </div>
      <div class="form-input-block">
        <label for="password">Password</label>
        <input type="password" v-model="password" class="form-control" id="passwordReg" placeholder="Enter password">
      </div>
      <div class="form-input-block">
        <label for="password2">Repeat Password</label>
        <input type="password" v-model="password2" class="form-control" id="password2Reg" placeholder="Enter password again">
      </div>
      <button type="submit" class="btn btn-primary">Register User</button>
    </form>
</template>

<script>
export default {
  name: "Register",
  props: {
    title: String
  },
  data() {
    return {
      username: "",
      email:"",
      password: "",
      password2: "",
      userType:"user"
    };
  },
  methods:{
    register: function() {
      var userRegister = {
          username: this.username,
          email: this.email,
          password: this.password,
          password2: this.password2,
          first_name:"",
          last_name:"",
          is_staff: false,
          is_superuser: false,
          user_type:"technician"
      }
      this.$store
        .dispatch("user/register", userRegister)
        .then(() => this.$router.go())
        .catch(err => console.log(err));
    }
  }
}


</script>

<style scoped>

.btn{
  margin-top: 1rem;
}

.form-input-block{
  margin-top: 1rem;
}

</style>