<template>
<div class="card" style="width: 18rem;">
  <img class="card-img-top" :alt="`${data.imgAlt}`" :src="require(`@/assets/img/${data.imgFile}`)">
  <div class="card-body">
    <h5 class="card-title">{{data.title}}</h5>
    <p class="card-text">{{data.text}}</p>
    <a href="#" class="btn btn-primary">{{data.buttonText}}</a>
  </div>
</div>
</template>

<script>
export default {
  name: "CardImage",
  props: {
    data: {}
  }
};
</script>

<style scoped lang="scss">

.card{
  margin: 3rem !important;
  border:none;
}

</style>
