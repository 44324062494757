import Vue from "vue";

const toaster_success = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "success",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_error = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "error",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

const toaster_info = (msg) => {
  return Vue.$toast.open({
    message: msg,
    type: "info",
    position: "top",
    pauseOnHover: true,
    dismissible: true,
    duration: 5000,
  });
};

export { toaster_success, toaster_error, toaster_info };


export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

export function fliterDates(year,month,days_month) {
  if (!month){
    var year_start = year + '-01-01'
    var year_fin = year + '-12-31'
    return "start_date >= '" + year_start + "' AND start_date <= '" + year_fin + "'" 
  }else{
    var date_start = year + '-' + month + '-01'
    var date_fin = year + '-' + month + '-' + days_month
    return "start_date >= '" + date_start + "' AND start_date <= '" + date_fin + "'" 
  }
}

export function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = 2016
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }
  return years
}