<template>
  <section>
      <div class="grid-flex">
              <b-avatar text="QS" size="6rem"></b-avatar>
              <h1>Hi! <span class="text-color-2">{{first_name}} {{last_name}}</span>! Welcome to QuietSEAS</h1>
      </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "UserProfileHeader",
  computed: { 
    ...mapGetters("user",{
      isLoggedIn: 'isLoggedIn',
      username: 'username',
      first_name: 'first_name',
      last_name: 'last_name',
    }) 
  },
  props: {
    data:{},
  }
}
</script>

<style scoped>
h1{
  font-weight: 700;
  font-size: 3vw;
  padding-left: 2rem;
}
p {
  font-weight: 500;
  font-size: 1.3vw;
  align-items: left;
  justify-content: left;
}

.grid-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  flex-direction: row; 
  -webkit-flex-direction: row;
  flex-wrap:wrap;
  -webkit-flex-wrap: wrap;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  margin: 2rem;
}


@media (max-width: 640px) {
  .grid-flex {
    height: 40em;
    display: -webkit-flex;
    -webkit-flex-direction: column;
    flex-direction: column; 
   }
  .col {
    order:vertical;
  }
  .col-left {
    -webkit-box-ordinal-group:0;
    -webkit-order: 0;
    -ms-flex-order: 0;
    order: 0;
  }
  .col-text div p {
    padding: 1em;
  }
  .Aligner-item {
    width: 90%;
  }
}

</style>