<template>
  <div>
    <div class="row justify-content-center margin-bottom-1rem">
      <div class="col-2 text-align-left">
        <label for="geometry-type-select">Geometry type</label>
      </div>
      <div class="col-2 text-align-left">
        <select class="form-select form-select-lg mb-3" v-model="geometry_type_noise_events_data" id="geometry-type-select">
          <option value="Point">Point</option>
          <option value="Line">Line</option>
          <option value="Polygon">Polygon</option>
          <option value="ReportingUnit">Reporting Unit</option>
          <option value="All">All</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeometryType",
  data() {
    return {
    }
  },
  computed: {
    geometry_type_noise_events_data: {
        set(data) {this.$store.dispatch("download/change_geometry_type_noise_events_data", data) },
        get() {return this.$store.state.download.geometry_type_noise_events_data },
      },
  },
}
</script>

<style scoped>

</style>