<template>
  <main class="container-fluid p-0" id="main">
    <section class=" thumb-container download-header">
        <div class="container-fluid h-100">
          <div class="row h-100" style="margin-top: 109px;">
            <div class="col-6 mt-5">
                <span class="download-header-content">
                  INR Report No Data
                </span>
            </div>
          </div>
        </div><!--container_fluid-->
    </section>
    <div class="section" style="margin-top: 10px;">
      <h2 class="actions-header">No Data Form</h2>
      <p>
        Please fill this form to report that your country doesn't have maritime
        noise events to report.
      </p>
      <FormNoData></FormNoData>
    </div>
    <div class="row m-5 steps-container">
      <button class="btn button-download-excel btn-primary"  @click="showReportNoData=!showReportNoData"> 
                Show report no data list
      </button>
      <table v-if="showReportNoData" class="table mt-4">
        <thead>
          <tr>
            <th scope="col">Country</th>
            <th scope="col">Year</th>
            <th scope="col">Airgun Array</th>
            <th scope="col">Explosion</th>
            <th scope="col">Generic Explicitly Impulse Noise</th>
            <th scope="col">Impact Pile Driver</th>
            <th scope="col">Sonar or Acustic Deterrents</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in noDataList" :key="item.id">
            <td>{{item.country.country}}</td>
            <td>{{item.year}}</td>
            <td>
              <img v-if="item.airgunNoise != true" :src="require('@/assets/icons/icon_tick.png')"  class="icon_check" />
              <img v-else :src="require('@/assets/icons/icon_x.png')"  class="icon_x" />
            </td>
            <td>
              <img v-if="item.explosionNoise != true" :src="require('@/assets/icons/icon_tick.png')"  class="icon_check" />
              <img v-else :src="require('@/assets/icons/icon_x.png')"  class="icon_x" />
            </td>
            <td>
              <img v-if="item.genericExplicitlyNoise != true" :src="require('@/assets/icons/icon_tick.png')"  class="icon_check" />
              <img v-else :src="require('@/assets/icons/icon_x.png')"  class="icon_x" />
            </td>
            <td>
              <img v-if="item.impactPileNoise != true" :src="require('@/assets/icons/icon_tick.png')"  class="icon_check" />
              <img v-else :src="require('@/assets/icons/icon_x.png')"  class="icon_x" />
            </td>
            <td>
              <img v-if="item.sonarNoise != true" :src="require('@/assets/icons/icon_tick.png')"  class="icon_check" />
              <img v-else :src="require('@/assets/icons/icon_x.png')"  class="icon_x" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
  // // @ is an alias to /src
  import { mapState } from "vuex";
  import Navbar from "@/components/Navbar/Navbar.vue";
  import UserProfileHeader from "@/components/User/UserProfileHeader.vue";
  import FormNoData from "@/components/FormNoData/FormNoData.vue";
  import {getData} from "@/services/api"
  import { fa } from "vuejs-datepicker/src/locale";

  export default {
    name: "NoData",
    components: {
      Navbar,
      UserProfileHeader,
      FormNoData,
    },
    data() {
      return {
        // lastState:'default',
        // isLoading: false,
        // fullPage: false,
        // loader: 'spinner',
        noDataList: [],
        showReportNoData: false,
      };
    },
    computed: mapState({
      statusUi: (state) => state.statusUi,
    }),
    created: function () {
      this.getNoData()
    },
    methods: {
      getNoData(){
        var url = 
        getData('upload/no-data-form/')
          .then((response) => {
            console.log(response.data)
            this.noDataList = response.data
          })
      }
    },
  };
</script>

<style scoped lang="scss">
  @import "../../styles/main.scss";

  p {
    font-weight: 500;
    font-size: 1.3vw;
    align-items: left;
    justify-content: left;
  }

  .section {
    margin-top: 5rem;
  }

  .icon_x{
    height: 15px; 
   // margin-right: 12px;
  }
  .icon_check{
    height: 28px; 
    // margin-right: 12px;


  }

  .divider {
    margin-top: 4rem;
    color: $primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before,
    &::after {
      content: "";
      display: block;
      height: 0.09em;
      min-width: 30vw;
    }

    &::before {
      background: $primary;
      margin-right: 4vh;
    }

    &::after {
      background: $primary;
      margin-left: 4vh;
    }
  }

  .download-header {
    height: 480px;
    background-image: url("~@/assets/img/download_data_dark.png");
    background-size: cover;
    margin-bottom: 20px;
  }
  .download-header-content {
    /* position: absolute; */
    max-width: 800px;
    padding: 3rem 2rem 1rem 0;
    // color: $secondary;
    color: white;
    /* text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff,
               1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff; */
    font-size: 3.2em;
    font-family: GothamRndBold, sans-serif;
    font-weight: bold;
  }
</style>
