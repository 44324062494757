<template>
  <div class="section">
    <h2>Reset your account password using this form</h2>
    <div class="forms-block">
      <form @submit.prevent="resetPassword">
      <div class="form-input-block">
        <label for="email">Your Email</label>
        <input type="email" class="form-control" v-model="email" id="email" placeholder="Your account email">
      </div>
      <div class="button-containter">
          <button type="submit" class="btn btn-primary">Reset Password</button>
      </div>
    </form>
    </div>
    <p class="login-link">Back to <b-link v-on:click="goToRegister">Register Page</b-link> or <b-link v-on:click="goToLogin">Login</b-link></p>
  </div>
</template>

<script>

import Navbar from "@/components/Navbar/Navbar.vue";
import { mapGetters } from 'vuex'


export default {
  name: "ResetPassword",
  components: {
    Navbar,
  }, 
  computed: { 
    ...mapGetters("ui",{
      showLogin:'showLogin'
    }) 
  },
  data() {
    return {
      email: "",
    };
  },
  methods:{
    resetPassword: function() {
      var userdata = {
          email: this.email
      }
      this.$store
        .dispatch("user/resetPassword", userdata)
        .then(() => this.$router.push("check-email"))
        .catch(err => console.log(err));
    },
    goToLogin: function() {
      this.$store
        .dispatch("ui/showLogin", true)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    },
    goToRegister: function() {
      this.$store
        .dispatch("ui/showLogin", false)
        .then(() => this.$router.push("login"))
        .catch(err => console.log(err));
    }
  },
};
</script>

<style scoped>
.section{
    margin-top: 5rem;
}

.forms-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 4rem;
}

.form{
  min-width: 400px;
}

.button-containter{
    width: 100%;
    height: 70px;
    margin-top: 1rem;
}
</style>